import moment from 'moment';
export default {
	filters: {
		momentDate: function (date) {
			return moment(date).format('DD.MM.YYYY');
		},
		momentTime: function (date) {
			return moment(date).format('HH:mm');
		},
		momentDateTime: function (date) {
			return moment(date).format('DD.MM.YYYY HH:mm');
		},
		toMB: function (bytes) {
			return (bytes / 1024 / 1024).toFixed(2) + ' MB';
		},
		toEur: function (value) {
			return parseFloat(value).toFixed(2) + ' €';
		},
		momentDateChat: function (date) {
			return moment(date).isSame(moment(), 'day')
				? moment(date).format('HH:mm')
				: moment(date).format('DD.MM.YYYY HH:mm');
		}
	}
};
