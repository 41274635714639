<template>
	<div class="message-list">
		<ul>
			<li
				v-for="(thread, index) in messageList"
				:key="index"
				@click.capture="$emit('selectMessage', thread.recordUUID)"
				:class="{ unread: !thread.read && !thread.senderInOwnOrganization }"
			>
				<div class="order">
					<span>{{ thread.record.id }}</span>
				</div>
				<div class="last-message">
					<span class="text" v-if="!thread.hasAttachment">
						{{ thread.text }}
					</span>
					<span class="text" v-else>
						<em>Dateianhang</em>
					</span>
					<span class="time">{{ thread.sendTime | momentDateChat }}</span>
				</div>
			</li>
			<li v-if="messageList.length === 0" class="empty">
				Noch keine Nachrichten verfügbar
			</li>
		</ul>
	</div>
</template>

<script>
import formatMixin from '@/mixins/format.mixin';
import cryptoMixin from '@/mixins/crypto.mixin';
import rolesMixin from '@/mixins/roles.mixin';
export default {
	mixins: [formatMixin, cryptoMixin, rolesMixin],
	data() {
		return {
			messageList: []
		};
	},
	mounted() {
		this.getMessageThreads();
	},
	methods: {
		async getMessageThreads() {
			const messageThreadsResponse = await this.$api.post(
				'/messages/get_message_threads',
				{
					userUUID: this.$store.getters.getUser.id,
					doctorUUID:
						this.$store.getters.getAppMode === 'doctor'
							? this.$store.getters.getDoctor.id
							: null,
					labUUID:
						this.$store.getters.getAppMode === 'lab'
							? this.$store.getters.getLab.id
							: null
				},
				{
					headers: {
						Authorization: `Bearer ${this.$store.getters.getUserToken}`
					}
				}
			);
			for (let i = 0; i < messageThreadsResponse.data.length; i++) {
				let iv = new Uint8Array(
					Object.values(JSON.parse(messageThreadsResponse.data[i].record.iv))
				);

				const enc_aes_key =
					this.$store.getters.getAppMode === 'doctor'
						? messageThreadsResponse.data[i].record.aes_key_doc
						: this.$store.getters.getAppMode === 'lab'
						? messageThreadsResponse.data[i].record.aes_key_lab
						: null;
				const private_key = await this.$store.getters.getPrivateKey;
				let key = await this.decryptAESKey(
					this.str2ab(atob(enc_aes_key)),
					private_key
				);
				this.decryptMessageTextAndAddToList(
					messageThreadsResponse.data[i],
					iv,
					key
				);
			}
		},
		async decryptMessageTextAndAddToList(message, iv, key) {
			let messageText = await this.decryptFileAES(
				iv,
				this.str2ab(atob(message.text)),
				key
			);
			message.text = this.ab2str(messageText);
			this.messageList.push(message);
		}
	}
};
</script>

<style lang="scss">
.message-list {
	overflow-y: scroll;
	width: 100%;
	max-height: 100%;
	height: 100%;
	ul {
		li {
			list-style-type: none;
			list-style: none;
			display: grid;
			grid-template-columns: 1fr 4fr;
			padding: 0.5rem;
			border-bottom: 1px solid $grey;
			&.unread {
				background-color: rgba(90, 141, 185, 0.3);
			}
			.order {
				width: calc(50px + 0.25rem);
				height: calc(50px + 0.25rem);
				border: 1px solid $primaryColor;
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				padding: 0.25rem;
				span {
					font-size: 0.75rem;
					white-space: pre;
				}
			}
			.last-message {
				width: 100%;
				display: grid;
				grid-template-rows: 2fr 1fr;
				align-items: center;
				.text {
					font-weight: 600;
				}
				.time {
					justify-self: end;
					font-size: 0.75rem;
				}
			}
			&.empty {
				grid-template-columns: auto;
				justify-content: center;
				align-items: center;
				font-weight: 600;
				padding: 1rem 0;
			}
		}
	}
}
</style>
