export default {
	computed: {
		getDoctorRoles() {
			return [
				{ name: 'Admin', value: 'doctor-admin' },
				{ name: 'User', value: 'doctor-user' }
			];
		}
	},
	methods: {
		isDoctor(role) {
			return this.parseRoleName(role).domain === 'doctor';
		},
		isLab(role) {
			return this.parseRoleName(role).domain === 'lab';
		},
		isAdmin(role) {
			return this.parseRoleName(role).role === 'admin';
		},
		sameAsMode(role) {
			return this.parseRoleName(role).domain === this.$store.getters.getAppMode;
		},
		parseRoleName(roleName) {
			const roleSplit = roleName.split('-');

			return { domain: roleSplit[0], role: roleSplit[1] };
		}
	}
};
